import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				ТехноСервіс Плюс
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:title"} content={"ТехноСервіс Плюс"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті побутової техніки та електроніки"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link">
				Головна
			</Override>
			<Override slot="link2">
				Послуги
			</Override>
			<Override slot="link3">
				Контакти
			</Override>
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667169091d712a0023351913/images/Mobile-Phone-Repair.jpg?v=2024-06-18T12:20:25.523Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" href="/contacts">
				Контакти
			</Override>
			<Override slot="text1">
				Ми пропонуємо широкий спектр професійних послуг з ремонту та обслуговування побутової техніки, електроніки, комп'ютерів та мобільних пристроїв.
			</Override>
			<Override slot="text">
				Послуги
			</Override>
			<Override slot="text2">
				Наша команда складається з висококваліфікованих майстрів, які використовують сучасне обладнання та оригінальні запчастини для забезпечення найвищої якості робіт.Наші послуги спрямовані на охоплення всіх аспектів футбольних тренувань, забезпечуючи всебічний ріст і розвиток. Ми обслуговуємо різні вікові групи та рівні навичок, від молодих претендентів до досвідчених спортсменів, які прагнуть удосконалити свою техніку.
			</Override>
			<Override slot="text3" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					На цій сторінці ви знайдете детальний опис кожної з наших послуг, включаючи ремонт побутової техніки, електроніки, комп'ютерів та мобільних пристроїв, а також гарантійний ремонт і діагностику. Ми прагнемо забезпечити максимальний комфорт для наших клієнтів, тому надаємо послуги швидко, якісно та з індивідуальним підходом до кожного замовлення.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1526406915894-7bcd65f60845?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12:20:25.520Z) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Наші основні послуги включають
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12:20:25.510Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/repairpic.jpg?v=2024-06-18T12%3A20%3A25.510Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Ремонт побутової техніки
								</Strong>
								<br />
								<br />
								"ТехноСервіс Плюс" спеціалізується на ремонті побутової техніки всіх видів. Ми виконуємо ремонт холодильників, пральних машин, посудомийних машин, духовок, мікрохвильових печей та іншої побутової техніки. Наші майстри швидко і якісно вирішать будь-які технічні проблеми, забезпечуючи довговічність та надійність вашої техніки.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12:20:25.524Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/Reasons-to-Choose-Local-Cell-Phone-Repair-Shops.jpeg?v=2024-06-18T12%3A20%3A25.524Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Ремонт електроніки
								</Strong>
								<br />
								<br />
								Ми пропонуємо професійний ремонт електроніки, включаючи телевізори, аудіосистеми, відеокамери та іншу електроніку. Наші спеціалісти мають великий досвід роботи з різними моделями та брендами, що дозволяє нам гарантувати високу якість ремонту. Ми використовуємо лише оригінальні запчастини та сучасні технології для діагностики та ремонту.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12:20:25.523Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/images1204-5c9b388b0066b.jpg?v=2024-06-18T12%3A20%3A25.523Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									{" "}Ремонт комп'ютерів
								</Strong>
								<br />
								<br />
								Комп'ютери є невід'ємною частиною нашого життя, і ми розуміємо, наскільки важливо, щоб вони працювали бездоганно. Ми пропонуємо ремонт та обслуговування комп'ютерів, ноутбуків, серверів та іншої комп'ютерної техніки. Наші майстри вирішать будь-які проблеми, пов'язані з апаратним або програмним забезпеченням, щоб ваш комп'ютер працював швидко та ефективно.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12:20:25.536Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/smartphone_repair_area.png?v=2024-06-18T12%3A20%3A25.536Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Ремонт мобільних пристроїв
								</Strong>
								<br />
								<br />
								Сучасні мобільні пристрої є надзвичайно важливими для нашого повсякденного життя. Ми пропонуємо ремонт смартфонів, планшетів та інших мобільних пристроїв будь-якої складності. Наші майстри мають великий досвід у ремонті мобільної техніки, що дозволяє нам швидко вирішувати будь-які проблеми та відновлювати працездатність ваших пристроїв.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12:20:25.518Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/25c4c41e5fd8db24b09908f154903fcd.jpg?v=2024-06-18T12%3A20%3A25.518Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									Гарантійний ремонт
								</Strong>
								<br />
								<br />
								"ТехноСервіс Плюс" також надає послуги гарантійного ремонту техніки. Якщо ваш пристрій ще знаходиться на гарантії, ви можете звернутися до нас для безкоштовного ремонту. Ми співпрацюємо з багатьма виробниками техніки та офіційними дистриб'юторами, що дозволяє нам забезпечити високий рівень обслуговування та відповідність всім гарантійним вимогам.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12:20:25.511Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/free-cell-phone-repair-2-990x500-1.jpg?v=2024-06-18T12%3A20%3A25.511Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								<Strong>
									{" "}Діагностика та консультації
								</Strong>
								<br />
								<br />
								Перед початком ремонту ми проводимо детальну діагностику вашої техніки, щоб виявити всі можливі проблеми та запропонувати найкраще рішення. Ми також надаємо професійні консультації з питань експлуатації та обслуговування техніки, що допоможе вам уникнути майбутніх проблем та продовжити термін служби ваших пристроїв.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12:20:25.520Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667169091d712a0023351913/images/rs=h_1000,cg_true,m.webp?v=2024-06-18T12%3A20%3A25.520Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 2rem"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
					text-transform="uppercase"
				>
					Оберіть послугу, яка вас цікавить, та дізнайтеся більше про наші можливості. Незалежно від складності проблеми, ми готові допомогти вам відновити працездатність вашої техніки та забезпечити її надійну роботу на довгі роки. Довірте ремонт техніки професіоналам – обирайте "ТехноСервіс Плюс"!
				</Text>
			</Box>
		</Section>
		<Components.Nav />
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});